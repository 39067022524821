import React from "react";
import Layout from "../components/layout.js"
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

const i18n = {
  'cn': {
    title: '系统定制',
    contact: (
      <Grid Container>
        <Grid xs={12}>
          <Typography>
            系统定制咨询请联系: <Link href='mailto: support@nanos.cloud'>support@nanos.cloud</Link>，或者加入QQ群Nano Cloud(819161083)
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  'en': {
    title: 'Get a quote',
    contact: (
      <Grid Container>
        <Grid xs={12}>
          <Typography>
            For consult or get a quote for customized version, please mail to <Link href='mailto: support@nanos.cloud'>support@nanos.cloud</Link>
          </Typography>
        </Grid>
      </Grid>
    ),
  },
}

export default function(props){
  const defaultLang = 'cn';
  const lang = props.pageContext.lang || defaultLang;
  const texts = i18n[lang];
  return (
      <Layout title={texts.title} lang={lang}>
        <Container maxWidth="md">
          <Box pt={5} pb={5}>
            <Grid Container>
              <Grid xs={12}>
                <Typography variant='h5'>
                  {texts.title}
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Box m={1} p={3}>
                  {texts.contact}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Layout>
  );
}
